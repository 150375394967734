import React, { FC } from "react"
import {
  Heading,
  Container,
  Paragraph,
  ButtonLink,
  Icon,
  Spacer,
} from "../../components/components"
import styled from "styled-components"
import { H1Raw } from "../../components/Heading/Heading"

const Error: FC<errorProps> = () => {
  return (
    <Container
      style={{ justifyContent: "center", alignItems: "center", width: "100%" }}
    >
      <Wrapper>
        <ErrorText>404</ErrorText>
        <Heading tag="h1" style={{ paddingTop: "3rem" }}>
          Huch! Es fehlt etwas.
        </Heading>
        <PulledPlugIcon />
        <Wire />
        <Spacer gutter={220} />
        <TopAdjustment>
          <Paragraph maxWidth="400px" textAlign="center">
            Die von ihnen gesuchte Seite existiert nicht oder wurde verschoben.
            Bitte überprüfen Sie die URL oder kehren Sie zur Startseite zurück
          </Paragraph>
          <CenterWrapper>
            <ButtonLink to="/">Zur Startseite</ButtonLink>
          </CenterWrapper>
        </TopAdjustment>
        <Spacer gutter={100} />
      </Wrapper>
    </Container>
  )
}

type errorProps = {}

export default Error

const TopAdjustment = styled.div`
  /* margin-top: -100px; */
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1280px;
  flex-direction: column;
`

const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ErrorText = styled(H1Raw)`
  font-size: 130px;
  padding-top: 5rem;
`

const Wire = styled.div`
  position: absolute;
  left: -220rem;
  height: 26.5px;
  width: 3000px;
  bottom: 385.5px;
  background: ${props => props.theme.palette.blue.vibrant};
`

const PulledPlugIcon = styled(Icon.PulledPlug)`
  position: absolute;
  left: -40rem;
  bottom: 298.5px;
  width: 120rem;
  height: 200px;
`
